




































































































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { IContact, PayloadState } from '@/types/types';
const contactModule = namespace('contact');
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { numeric, required, min, max } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid phone number provided',
});
extend('min', {
  ...min,
  message: `Invalid phone number provided`,
});
extend('max', {
  ...max,
  message: `Maximum phone number must be 13`,
});

@Component({
  name: 'AddPaymentMethod',
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class GetLink extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @contactModule.Getter('getContactDetails') details!: IContact;

  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;

  menu = false;
  contact: IContact = {
    id: '',
    name: '',
    phone: '',
    dateOfBirth: null,
  };

  @Watch('details')
  onContactDetailsChanged(payload: IContact): void {
    this.contact = { ...payload };
  }

  @Emit('contact')
  editContact(): IContact {
    return this.contact;
  }

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'edit',
      state: false,
    };
  }
}
